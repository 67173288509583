import { ChainId } from '@pancakeswap/chains'
import { Token } from '@pancakeswap/sdk'
import {
  ImageProps,
  TokenImage as UIKitTokenImage,
  TokenPairImage as UIKitTokenPairImage,
  TokenPairImageProps as UIKitTokenPairImageProps,
} from '@pancakeswap/uikit'
import { ASSET_CDN } from 'config/constants/endpoints'

interface TokenPairImageProps extends Omit<UIKitTokenPairImageProps, 'primarySrc' | 'secondarySrc'> {
  primaryToken: Token
  secondaryToken: Token
}

export const tokenImageChainNameMapping = {
  [ChainId.BSC]: '',
  [ChainId.ETHEREUM]: 'eth/',
  [ChainId.POLYGON_ZKEVM]: 'polygon-zkevm/',
  [ChainId.ZKSYNC]: 'zksync/',
  [ChainId.ARBITRUM_ONE]: 'arbitrum/',
  [ChainId.LINEA]: 'linea/',
  [ChainId.ZEUSCHAIN]: 'zeuschain/',
  [ChainId.PULSECHAIN]: 'token-logo/',
  [ChainId.UNICHAIN_SEPOLIA]: 'unichain-sepolia/',
  [ChainId.WORLDCHAIN]: 'worldchain/',
  [ChainId.APECHAIN]: 'apechain/',
  [ChainId.MORPHCHAIN]: 'morphchain/',
  [ChainId.INKCHAIN]: 'inkchain/',
  [ChainId.BASECHAIN]: 'base-logos/',
  [ChainId.SONIC]: 'sonic-logo/',
  [ChainId.OPBNB]: 'opbnb/',
}

export const getImageUrlFromToken = (token: Token) => {
  const address = token?.isNative ? token.wrapped.address : token.address

  return token?.isNative && token.chainId !== ChainId.BSC
    ? `${ASSET_CDN}/web/native/${token.chainId}.png`
    : `https://raw.githubusercontent.com/9mm-exchange/app-tokens/refs/heads/main/${tokenImageChainNameMapping[token.chainId]}${address}.png`
}

export const TokenPairImage: React.FC<React.PropsWithChildren<TokenPairImageProps>> = ({
  primaryToken,
  secondaryToken,
  ...props
}) => {
  return (
    <UIKitTokenPairImage
      primarySrc={getImageUrlFromToken(primaryToken)}
      secondarySrc={getImageUrlFromToken(secondaryToken)}
      {...props}
    />
  )
}

interface TokenImageProps extends ImageProps {
  token: Token
}

export const TokenImage: React.FC<React.PropsWithChildren<TokenImageProps>> = ({ token, ...props }) => {
  return <UIKitTokenImage src={getImageUrlFromToken(token)} {...props} />
}
